<style>
.v-dialog__content.v-dialog__content--active {
  z-index: 999999 !important;
}
</style>
<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold; '"
      >
        Complete profile
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Just add your agency logo and some info about the agency.<br />
        <b>Who we are:</b> Your agency's mission, vision and history. <br />
        <b>What we do:</b> How your agency accomplishes its mission and vision:
        Its programs and activities...
      </v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column"
        style="font-family:'Poppins-Medium';color: #1e1e1e"
      >
        Select Logo
        <div class="d-flex justify-center">
          <editable-avatar
            :image="profile.midThumbnail ? profile.midThumbnail : profile.image"
            :onImageSelected="onImageSelected"
          />
        </div>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
          Who we are
        </div>
        <v-textarea
          rows="4"
          auto-grow
          label="Who we are"
          solo
          flat
          outlined
          class="mt-2"
          v-model="profile.who_we_are"
          style="font-family:'Poppins-Regular';"
          :rules="[rules.required]"
          hide-details
        />
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
          What we do
        </div>
        <v-textarea
          rows="4"
          auto-grow
          label="What we do"
          solo
          flat
          outlined
          class="mt-2"
          v-model="profile.what_we_do"
          style="font-family:'Poppins-Regular';"
          :rules="[rules.required]"
          hide-details
        />
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Continue</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
export default {
  components: { EditableAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      code: "",
      who_we_are: "",
      what_we_do: "",
      message: null,
      loading: false,
      sendingCode: false,
      disableResend: false,
      resendCount: 0,
      rules: {
        required: value => !!value || "Required."
      },
      selectedImage: null
    };
  },
  methods: {
    ...mapActions("auth", {
      completeProfile: "completeProfile"
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      if (!this.profile.image && !this.selectedImage) {
        this.message = "Please select the agency logo.";
        return;
      }
      if (!this.profile.who_we_are) {
        this.message = "Please add 'Who we are'.";
        return;
      }
      if (!this.profile.what_we_do) {
        this.message = "Please add 'What we do'.";
        return;
      }
      this.message = null;
      var formData = new FormData();
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      } else {
        return;
      }
      formData.append("who_we_are", this.profile.who_we_are);
      formData.append("what_we_do", this.profile.what_we_do);
      this.loading = true;
      this.completeProfile(formData)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch(error => {
          this.loading = false;
          this.message = error.response.data.message;
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
      this.message = null;
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile"
    })
  }
};
</script>
<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
