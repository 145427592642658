<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Complete Profile
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Add your agency's links.
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column mt-3">
        <v-row>
          <v-col cols="12" class="py-0">
            <v-text-field
              v-model="profile.website"
              label="Website"
              dense
              filled
              background-color="white"
              @input="onChange"
              outlined
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              v-model="profile.facebook"
              label="Facebook"
              dense
              filled
              background-color="white"
              @input="onChange"
              outlined
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              v-model="profile.twitter"
              label="Twitter"
              dense
              filled
              background-color="white"
              @input="onChange"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Continue</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      message: null,
      loading: false,
      rules: {
        required: value => !!value || "Required."
      },
      profile: {}
    };
  },
  methods: {
    ...mapActions("auth", {
      updateProfile: "updateProfile"
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      if (!this.profile.website) {
        this.message = "Agency website is required";
        return;
      }
      this.loading = true;
      this.updateProfile({
        website: this.profile.website,
        facebook: this.profile.facebook,
        twitter: this.profile.twitter
      })
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch(error => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    }
  },
  computed: {
    ...mapState("auth", {
      myProfile: "profile"
    })
  },
  watch: {
    myProfile(newValue) {
      this.profile = { ...newValue };
    }
  },
  mounted() {
    this.profile = { ...this.myProfile };
  }
};
</script>
<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
